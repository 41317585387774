$(function () {
  /*---------------ヘッダーのハンバーガーメニュー--------------- */
  $(".js-hamburger-button").on("click", function () {
    let stateNextHeaderMenu =
      $(".header").attr("data-is-headernav") === "true" ? false : true;
    $(".header").attr("data-is-headernav", stateNextHeaderMenu);
  });

  /*---------------ヘッダーのSPの検索のボタン--------------- */
  $(".js-search-button").on("click", function () {
    let stateNextHeaderToggleMenu =
      $(".header").attr("data-is-header-search-toggle") === "true"
        ? false
        : true;
    $(".header").attr(
      "data-is-header-search-toggle",
      stateNextHeaderToggleMenu
    );
  });

  $(".js-header-nav__toggle-button").on("click", function (e) {
    e.preventDefault();
    $(this).parent().next().slideToggle();
    let toggleButtonStatus = $(this).attr("data-toggle-button") === "open" ? "close" : "open";
    $(this).attr("data-toggle-button", toggleButtonStatus);
  });
});